
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute, useRouter } from 'vue-router';

  import DarkLightImage from '@/components/DarkLightImage.vue';
  import Button from '@/components/Button.vue';
  import Emoji from '@/components/html-elements/Emoji.vue';

  export default defineComponent({
    name: 'Home',

    components: {
      DarkLightImage,
      Button,
      Emoji,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const route = useRoute();
      const router = useRouter();

      return { t, router, route };
    },
  });
