<template>
  <div
    class="dropdown"
    :class="{'landing': route.name === 'landing'}"
  >
    <button
      class="toggle"
      @click="showDialog = !showDialog;"
      @focusout="showDialog = false"
    >
      <div class="icon">
        <img
          :src="require(`@/assets/img/language-icons/${langs.get(locale).icon}.png`)"
          :alt="locale"
        >
      </div>
      <span class="text">{{ t(`locales.${locale}`) }}</span>
      <div
        class="arrow"
        :class="{'up': showDialog}"
      >
        <!-- eslint-disable -->
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.608279 0.683058C0.86398 0.438981 1.27855 0.438981 1.53425 0.683058L4.99984 3.99112L8.46542 0.683058C8.72112 0.438981 9.13569 0.438981 9.39139 0.683058C9.64709 0.927136 9.64709 1.32286 9.39139 1.56694L5.46282 5.31694C5.20712 5.56102 4.79255 5.56102 4.53685 5.31694L0.608279 1.56694C0.352579 1.32286 0.352579 0.927136 0.608279 0.683058Z" />
        </svg>
        <!-- eslint-enable -->
      </div>
    </button>
    <transition name="dropdown-dialog">
      <ul
        v-show="showDialog"
        class="dialog"
      >
        <li
          v-for="[id, lang] in langs"
          :key="id"
          class="dialog-item"
          :class="{'active': id === locale}"
          @click="showDialog = false; selectLanguage(id);"
        >
          <div class="text primary">
            {{ lang.nativeName }}
          </div>
          <div class="text secondary">
            {{ t(`locales.${id}`) }}
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'LanguageSelector',

    setup() {
      const showDialog = ref(false);
      const route = useRoute();

      const { t, locale } = useI18n();

      const langs = ref(new Map([
        ['en-US', { nativeName: 'English', icon: 'en' }],
        ['ru-RU', { nativeName: 'Русский', icon: 'ru' }],
      ]));

      const selectLanguage = (l: string) => {
        localStorage.setItem('selectedLanguage', l);
        locale.value = l;
      };

      return {
        showDialog, langs, locale, t, selectLanguage, route,
      };
    },
  });
</script>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .toggle {
    background: none;
    border: 1px solid rgb(var(--color-secondary-border));
    border-radius: .375rem;
    outline: none;
    display: flex;
    align-items: center;
    padding: 0 .75rem;
    color: rgb(var(--color-text-primary));
    font-size: .875rem;
    font-weight: 500;
    transition: background-color .2s ease-out;
    height: var(--header-element-height, 2.5rem);

    &:hover {
      cursor: pointer;
      background-color: rgb(var(--color-secondary-hover));
    }

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 1.125rem;
      }
    }

    .text {
      margin: 0 .25rem;
    }

    .arrow {
      display: inline-block;
      transition: all .3s ease-out;
      position: relative;

      svg {
        width: .875rem;
        height: auto;

        path {
          fill: rgb(var(--color-secondary-icon));
        }
      }

      &.up {
        transform: rotate(180deg)
      }
    }
  }

  &.landing {
    .toggle {
      @media (max-width: 576px) {
        .text {
          display: none;
        }

        .icon {
          margin-right: .5rem;
        }
      }
    }
  }

  .dialog {
    list-style: none;
    margin: 0;
    padding: .375rem 0;
    background-color: rgb(var(--color-background));
    border: 1px solid rgb(var(--color-secondary-border));
    border-radius: .375rem;
    position: absolute;
    right: 0;
    top: calc(100% + .25rem);
    width: 10rem;
    box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 0.1);
    font-weight: normal;

    .dialog-item {
      padding: .375rem 1rem;

      & .text.primary {
        color: rgb(var(--color-text-primary));
        font-size: .875rem;
      }

      &.active .text.primary {
        font-weight: bold;
      }

      .text.secondary {
        color: rgb(var(--color-text-secondary));
        font-size: .75rem;
      }
    }

    li:hover {
      cursor: pointer;
      background-color: rgb(var(--color-secondary-hover));
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(.8);
  opacity: 0;
}
</style>
