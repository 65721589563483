
  import { defineComponent, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useRoute, RouteRecordName } from 'vue-router';

  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';

  export default defineComponent({
    name: 'App',

    components: {
      Header,
      Footer,
    },

    setup() {
      const {
        t, locale, availableLocales,
      } = useI18n({ useScope: 'global' });
      const store = useStore();
      const route = useRoute();

      // Get selected theme (light/dark) or use system
      store.dispatch('theme/init');

      // Get selected language or use language set by browser

      const selectedLanguage = localStorage.getItem('selectedLanguage');

      if (selectedLanguage) {
        locale.value = selectedLanguage;
      } else {
        for (let localeIx = 0; localeIx < availableLocales.length; localeIx += 1) {
          const l = availableLocales[localeIx];

          if (navigator.language.includes(l)) {
            locale.value = l;
          }
        }
      }

      // Update title on locale update

      const updateTitle = (page: RouteRecordName | null | undefined) => {
        document.title = page ? t(`pages.${page.toString()}.meta.title`) : 'Typing School';
      };

      watch(locale, () => {
        updateTitle(route.name);
      });

      // Fix 100% height for Safari
      // 1 - mobile (hiding search bar)
      // 2 - desktop (additional bar when 2 or more sites are opened)

      function setHeight() {
        const vh = window.innerHeight * 0.01;
        document.body.style.setProperty('--vh', `${vh}px`);
      }

      setHeight();

      store.commit('windowResize/addHandler', { handler: setHeight });
      store.dispatch('windowResize/init');
      // window.onresize = setHeight;
      return { route };
    },
  });
