<template>
  <section class="home">
    <div class="main">
      <h1 class="title">
        {{ t(`pages.${route.name}.title`) }}&nbsp;<Emoji name="vulcan-salute" />
      </h1>
      <h2 class="subtitle">
        {{ t(`pages.${route.name}.subtitle`) }}
      </h2>
      <p class="description">
        {{ t(`pages.${route.name}.description`) }}
      </p>
      <div class="actions">
        <Button
          type="primary"
          @click="router.push({ name: 'layout-setup'})"
        >
          {{ t(`pages.${route.name}.actionButton`) }}
        </Button>
      </div>
      <small class="unavailable-on-mobile">{{ t(`pages.${route.name}.actionDisabled`) }}</small>
    </div>
    <div class="illustration">
      <DarkLightImage
        path="keyboard"
        ext="png"
        alt="Keyboard"
      />
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute, useRouter } from 'vue-router';

  import DarkLightImage from '@/components/DarkLightImage.vue';
  import Button from '@/components/Button.vue';
  import Emoji from '@/components/html-elements/Emoji.vue';

  export default defineComponent({
    name: 'Home',

    components: {
      DarkLightImage,
      Button,
      Emoji,
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' });
      const route = useRoute();
      const router = useRouter();

      return { t, router, route };
    },
  });
</script>

<style lang="scss" scoped>
section.home {
  display: grid;
  grid: "main" auto
        "illustration" min-content
         / 100%;
  justify-items: center;
  text-align: center;
}
// Main block

.main {
  grid-area: main;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;

  .title {
    margin: 0;
    margin-bottom: .625em;
    font-size: 3rem;
  }

  .subtitle {
    color: rgb(var(--color-text-primary));
    font-weight: 500;
    margin: 0;
    font-size: 1.25rem;
  }

  .description {
    color: rgb(var(--color-text-secondary));
    max-width: 35em;
    font-size: 1rem;
    margin: .75em auto 2em;
  }

  .actions {
    :deep(.btn) {
      font-size: 1rem;
    }
  }

  .unavailable-on-mobile {
    color: rgb(var(--color-text-tertiary));
    display: none;
    margin: 1em auto 0;
  }
}

// Illustration

.illustration {
  max-width: 50rem;
  height: calc(50rem / 4.71);
  grid-area: illustration;
  font-size: 0;
  position: relative;
  overflow: hidden;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgb(var(--color-background)) 5%, rgb(var(--color-background) / 0) 100%);
  }
}

// Responsiveness

@media (max-width: 576px) {
  .main {
    .actions {
      :deep(.btn) {
        pointer-events: none;
        opacity: .5;
      }
    }

    .unavailable-on-mobile {
      display: block;
    }

    .title {
      font-size: 2.25rem;
    }
  }

  .illustration {
    display: none;
  }
}
</style>
