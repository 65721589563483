import { LessonData, TodayStat } from '@/config/dataTypes';
import moment, { Duration, Moment } from 'moment';
import { ActionTree, GetterTree } from 'vuex';

type LessonModuleState = {
  lastLesson: {
    speed: number | null,
    accuracy: number | null,
    duration: Duration | null,
    text: string[] | null,
  },
};

const lessonModuleState: LessonModuleState = {
  lastLesson: {
    speed: null,
    accuracy: null,
    duration: null,
    text: null,
  },
};

const lessonModuleGetters = <GetterTree<LessonModuleState, null>>{
  last: (state): LessonData | null => {
    if (!state.lastLesson.speed || !state.lastLesson.accuracy || !state.lastLesson.duration) {
      return null;
    }

    return state.lastLesson as LessonData;
  },

  todayStat: (): TodayStat => {
    let todayMoment: Moment | null = null;
    let overallTime: Duration | null = null;
    let lessonsCount = 0;
    let avgSpeed = 0;
    let avgAccuracy = 0;

    if (localStorage.getItem('todayTimeStamp')) {
      const timestamp = moment(localStorage.getItem('todayTimeStamp'), 'MM-DD-YYYY');

      if (moment().diff(timestamp, 'days') === 0) {
        todayMoment = timestamp;

        if (localStorage.getItem('overallTime')) {
          overallTime = moment.duration(localStorage.getItem('overallTime'));
        }

        if (localStorage.getItem('lessonsCount')) {
          lessonsCount = Number(localStorage.getItem('lessonsCount'));
        }

        if (localStorage.getItem('avgSpeed')) {
          avgSpeed = Number(localStorage.getItem('avgSpeed'));
        }

        if (localStorage.getItem('avgAccuracy')) {
          avgAccuracy = Number(localStorage.getItem('avgAccuracy'));
        }
      }
    }

    return {
      lessonsCount,
      overallTime: overallTime || moment.duration(0),
      todayMoment: todayMoment || moment(),
      avgSpeed,
      avgAccuracy,
    };
  },
};

const lessonModuleActions = <ActionTree<LessonModuleState, null>>{
  save(context, data: LessonData) {
    context.state.lastLesson.speed = data.speed;
    context.state.lastLesson.accuracy = data.accuracy;
    context.state.lastLesson.duration = data.duration;
    context.state.lastLesson.text = data.text;

    let todayMoment: Moment | null = null;
    let overallTime: Duration | null = null;
    let lessonsCount: number | null = null;
    let avgSpeed = 0;
    let avgAccuracy = 0;

    if (localStorage.getItem('todayTimeStamp')) {
      const timestamp = moment(localStorage.getItem('todayTimeStamp'), 'MM-DD-YYYY');
      const now = moment();
      if (moment.duration(now.diff(timestamp)).days() === 0) {
        todayMoment = timestamp;
      }
    }

    if (!todayMoment) {
      todayMoment = moment();
      localStorage.removeItem('todayTimeStamp');
      localStorage.removeItem('overallTime');
      localStorage.removeItem('lessonsCount');
      localStorage.removeItem('avgSpeed');
      localStorage.removeItem('avgAccuracy');
    }

    if (localStorage.getItem('overallTime')) {
      overallTime = moment.duration(localStorage.getItem('overallTime'));
    } else {
      overallTime = moment.duration(0);
    }

    if (localStorage.getItem('lessonsCount')) {
      lessonsCount = Number(localStorage.getItem('lessonsCount'));
    } else {
      lessonsCount = 0;
    }

    const rawAvgSpeed = localStorage.getItem('avgSpeed');
    if (rawAvgSpeed) {
      avgSpeed = parseFloat(rawAvgSpeed);
    }

    const rawAvgAccuracy = localStorage.getItem('avgAccuracy');
    if (rawAvgAccuracy) {
      avgAccuracy = parseFloat(rawAvgAccuracy);
    }

    avgSpeed = ((avgSpeed * lessonsCount) + data.speed) / (lessonsCount + 1);
    avgAccuracy = ((avgAccuracy * lessonsCount) + data.accuracy) / (lessonsCount + 1);
    overallTime.add(data.duration);
    lessonsCount += 1;

    localStorage.setItem('todayTimeStamp', todayMoment.format('MM-DD-YYYY'));
    localStorage.setItem('overallTime', String(overallTime.asMilliseconds()));
    localStorage.setItem('lessonsCount', String(lessonsCount));
    localStorage.setItem('avgSpeed', String(avgSpeed));
    localStorage.setItem('avgAccuracy', String(avgAccuracy));
  },
};

const lessonModule = {
  namespaced: true,

  state: lessonModuleState,
  getters: lessonModuleGetters,
  actions: lessonModuleActions,
};

export {
  lessonModule,
};
