
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'DarkLightImage',

    props: {
      path: {
        type: String, // i.e. 'logo-beta'
        required: true,
      },
      ext: {
        type: String, // i.e. 'svg'
        require: true,
      },
      alt: {
        type: String, // i.e. 'Logo'
        default: '',
      },
    },

    setup() {
      const store = useStore();
      const theme = computed(() => store.state.theme.activeTheme);
      return { theme };
    },
  });
