
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import DarkLightImage from './DarkLightImage.vue';
  import LanguageSelector from './LanguageSelector.vue';

  export default defineComponent({
    name: 'Header',

    components: {
      DarkLightImage,
      LanguageSelector,
    },

    props: {
      separated: {
        type: Boolean,
        default: true,
      },
      metrics: { // {speed, accuracy}
        type: Object,
        required: false,
      },
    },

    setup() {
      const store = useStore();
      const route = useRoute();
      const theme = computed(() => store.state.theme.activeTheme);
      const { t } = useI18n();

      const resetLayout = () => {
        localStorage.removeItem('selectedLayoutName');
      };

      return {
        route, theme, store, resetLayout, t,
      };
    },
  });
