import { ActionTree, MutationTree } from 'vuex';

type WindowResizeState = {
  eventHandlers: (() => void)[],
};

const windowResizeState: WindowResizeState = {
  eventHandlers: [],
};

const windowResizeMutations = <MutationTree<WindowResizeState>>{
  addHandler(state, props: { handler: () => void }) {
    state.eventHandlers.push(props.handler);
  },
};

// TODO fix any in RootState generic type
const windowResizeActions = <ActionTree<WindowResizeState, null>>{
  init(context) {
    window.onresize = function resize() {
      context.state.eventHandlers.forEach((handler: () => void) => handler());
    };
  },
};

const windowResize = {
  namespaced: true,

  state: windowResizeState,
  mutations: windowResizeMutations,
  actions: windowResizeActions,
};

export {
  windowResize,
};
