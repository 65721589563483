{
  "pages": {
    "landing": {
      "meta": {
        "title": "Typing School"
      },
      "title": "Hello and welcome",
      "subtitle": "What is Typing School?",
      "description": "It's a\u00a0minimalistic keyboard trainer to\u00a0practice touch typing skill. Service is\u00a0still in\u00a0development mode, but you can already try some features.",
      "actionButton": "Try this out",
      "actionDisabled": "Unavailable from mobile"
    },
    "profile": {
      "meta": {
        "title": "Profile"
      },
      "lessonGenerator": {
        "title": "Trainer",
        "subtitle": "Select sets of\u00a0keys you want to\u00a0train",
        "options": ["Letters", "Numbers", "Symbols"],
        "actionButton": "Start"
      },
      "keyboardLayout": {
        "title": "Your layout",
        "subtitle": "Colemak",
        "actionButton": "Re-setup"
      },
      "themeSelector": {
        "title": "Color scheme",
        "light": "Light",
        "dark": "Dark",
        "system": "System"
      },
      "supportUs": {
        "title": "Feedback",
        "subtitle": "Here you can report a\u00a0bug or\u00a0suggest your ideas for the project development",
        "actions": {
          "suggestIdea": "Suggest idea",
          "reportBug": "Report bug"
        }
      }
    },
    "layout-setup": {
      "meta": {
        "title": "Keyboard setup"
      },
      "title": {
        "progress": "Keyboard setup",
        "done": "Your layout"
      },
      "steps": [
        {
          "title": "System",
          "description": "Which OS\u00a0are you using?"
        },
        {
          "title": "Format",
          "description": "What does your Enter (return) key look like?"
        },
        {
          "title": "Language",
          "description": "Which input language do\u00a0you want to\u00a0practice?"
        },
        {
          "title": "Layout",
          "description": [
            "Just press",
            " or\u00a0choose manually"
          ]
        }
      ],
      "actionButton": "Continue",
      "isWrong": "Wrong layout?",
      "restartAction": "Re-setup"
    },
    "trainer": {
      "meta": {
        "title": "Trainer"
      }
    },
    "result": {
      "meta": {
        "title": "Result"
      },
      "todayStat": {
        "lessons": "0 lessons | {n} lesson | {n} lessons",
        "minutes": "0 minutes | {n} minute | {n} minutes",
        "seconds": "0 seconds | {n} second | {n} seconds",
        "accuracy": "{val} accuracy"
      },
      "actionButtons": {
        "home": "Home",
        "repeat": "Repeat",
        "continue": "Next"
      }
    }
  },
  "footer": {
    "contactUs": "Contact us"
  },
  "systems": {
    "win-lnx": "Windows / Linux",
    "osx": "MacOS"
  },
  "layoutFormats": {
    "iso": "ISO",
    "ansi": "ANSI"
  },
  "languages": {
    "en": "English",
    "ru": "Russian"
  },
  "locales": {
    "en-US": "English",
    "ru-RU": "Russian"
  },
  "keyboardLayouts": {
    "ru": {
      "qwerty": "ЙЦУКЕН",
      "typewriter": "Typewriter",
      "russian-apple": "Russian (Apple)",
      "russian-apple-pc": "Russian – PC"
    },
    "en": {
      "qwerty": "QWERTY",
      "dvorak": "DVORAK",
      "colemak": "COLEMAK"
    }
  },
  "shortcutActions": {
    "pause": "Pause", 
    "continue": "Continue",
    "restart": "Restart"
  },
  "shortcutHelpers": {
    "press": "press",
    "to": "to"
  },
  "speedUnits": {
    "wpm": "wpm",
    "cpm": "cpm"
  },
  "durationUnits": {
    "sec": "sec",
    "min": "min"
  },
  "messages": {
  }
}