import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './locales/i18n';
import { analytics } from './firebase';

const app = createApp(App);

analytics.logEvent('app_initialized');

Sentry.init({
  app,
  dsn: 'https://afa76c23a73d42b68231a9dacb90fd01@o926704.ingest.sentry.io/5876329',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'typing.school', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app
  .use(router)
  .use(i18n)
  .use(store)
  .mount('#app');
