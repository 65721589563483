{
  "pages": {
    "landing": {
      "meta": {
        "title": "Typing School"
      },
      "title": "Добро пожаловать",
      "subtitle": "Что такое Typing School?",
      "description": "Это минималистичный клавиатурный тренажёр для тренировки навыка слепой десятипальцевой печати. Сервис ещё в\u00a0разработке, но\u00a0попробовать некоторые функции можно уже сейчас.",
      "actionButton": "Попробовать",
      "actionDisabled": "Недоступно со\u00a0смартфона"
    },
    "profile": {
      "meta": {
        "title": "Личный кабинет"
      },
      "lessonGenerator": {
        "title": "Тренажер",
        "subtitle": "Выберите нужные символы для тренировки",
        "options": ["Буквы", "Цифры", "Знаки"],
        "actionButton": "Старт"
      },
      "keyboardLayout": {
        "title": "Раскладка",
        "actionButton": "Перенастроить"
      },
      "themeSelector": {
        "title": "Тема оформления",
        "light": "Светлая",
        "dark": "Темная",
        "system": "Авто"
      },
      "supportUs": {
        "title": "Обратная связь",
        "subtitle": "Здесь вы\u00a0можете сообщить о\u00a0проблеме или предложить свои идеи по\u00a0развитию сервиса",
        "actions": {
          "suggestIdea": "Предложить идею",
          "reportBug": "Сообщить о\u00a0проблеме"
        }
      }
    },
    "layout-setup": {
      "meta": {
        "title": "Настройка клавиатуры"
      },
      "title": {
        "progress": "Настройка клавиатуры",
        "done": "Ваша раскладка"
      },
      "steps": [
        {
          "title": "Система",
          "description": "Какую ОС\u00a0вы\u00a0используете?"
        },
        {
          "title": "Формат",
          "description": "Как выглядит ваша клавиша ввода (Enter)?"
        },
        {
          "title": "Язык",
          "description": "Какой язык ввода вы\u00a0хотите тренировать?"
        },
        {
          "title": "Раскладка",
          "description": [
            "Просто нажмите",
            " или выберите вручную"
          ]
        }
      ],
      "actionButton": "Продолжить",
      "isWrong": "Не\u00a0та\u00a0раскладка?",
      "restartAction": "Перенастроить"
    },
    "trainer": {
      "meta": {
        "title": "Тренажер"
      }
    },
    "result": {
      "meta": {
        "title": "Результат"
      },
      "todayStat": {
        "lessons": "0 уроков | {n} урок | {n} урока | {n} уроков",
        "minutes": "0 минут | {n} минута | {n} минуты | {n} минут",
        "seconds": "0 секунд | {n} секунда | {n} секунды | {n} секунд",
        "accuracy": "точность {val}"
      },
      "actionButtons": {
        "home": "На\u00a0главную",
        "repeat": "Повторить",
        "continue": "Далее"
      }
    }
  },
  "footer": {
    "contactUs": "Связь"
  },
  "systems": {
    "win-lnx": "Windows / Linux",
    "osx": "MacOS"
  },
  "layoutFormats": {
    "iso": "ISO",
    "ansi": "ANSI"
  },
  "languages": {
    "en": "Английский",
    "ru": "Русский"
  },
  "locales": {
    "en-US": "Английский",
    "ru-RU": "Русский"
  },
  "keyboardLayouts": {
    "ru": {
      "qwerty": "ЙЦУКЕН",
      "typewriter": "Машинопись",
      "russian-apple": "Русская (Apple)",
      "russian-apple-pc": "Русская ПК"
    },
    "en": {
      "qwerty": "QWERTY",
      "dvorak": "DVORAK",
      "colemak": "COLEMAK"
    }
  },
  "shortcutActions": {
    "pause": "Пауза",
    "continue": "Продолжить",
    "restart": "Рестарт"
  },
  "shortcutHelpers": {
    "press": "нажмите",
    "to": "чтобы"
  },
  "speedUnits": {
    "wpm": "сл/м",
    "cpm": "зн/м"
  },
  "durationUnits": {
    "sec": "сек",
    "min": "мин"
  },
  "messages": {
  }
}