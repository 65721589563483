<template>
  <footer>
    <router-link
      to="/"
      class="logo"
    >
      <DarkLightImage
        path="logo-beta"
        ext="svg"
        alt="Typing School"
      />
      <span class="year">{{ moment().format('YYYY') }}</span>
    </router-link>
    <div class="nav-links">
      <div class="nav-link">
        <a href="mailto:hello@typing.school">hello@typing.school</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import moment from 'moment';
  import DarkLightImage from './DarkLightImage.vue';

  export default defineComponent({
    name: 'Footer',

    components: {
      DarkLightImage,
    },

    setup() {
      return { moment };
    },
  });
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid rgb(var(--color-secondary-border));
  display: flex;
  justify-content: space-between;
  padding: .75rem 0;
}

.logo {
  text-decoration: none;
  display: flex;
  align-items: center;

  :deep(img) {
    height: 2rem;
  }

  .year {
    margin-left: .25rem;
    color: rgb(var(--color-text-secondary));
    font-weight: 500;
  }
}

.nav-links {
  display: flex;
  align-items: center;

  .nav-link {
    color: rgb(var(--color-text-secondary));

    a {
      color: rgb(var(--color-text-secondary));
      transition: color .3s ease-out;
      vertical-align: middle;

      &:hover {
        color: rgb(var(--color-text-primary));
      }
    }
  }
}
</style>
