<template>
  <span
    class="emoji"
    :class="[type]"
  >
    <span>&#8203;</span>
    <img
      :src="require(`@/assets/img/emoji/${name}.png`)"
      draggable="false"
      :alt="name"
    >
    <span>&#8203;</span>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Emoji',

    props: {
      // File name from `img/emoji/`
      name: {
        type: String,
        required: true,
      },

      // Inline / separate
      type: {
        type: String,
        default: 'inline',
      },
    },
  });
</script>

<style lang="scss" scoped>
.emoji {
  white-space: nowrap;
  line-height: 1;

  img {
    height: 1.125em;
    width: 1.125em;
  }

  &.inline {
    img {
      vertical-align: middle;
      position: relative;
      bottom: .125em;
    }
  }
}
</style>
