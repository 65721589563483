import { createI18n } from 'vue-i18n';
import { Locales } from './locales';
import { messages } from './index';


/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 */
function slavicPluralization(choice: number, choicesLength: number) {
  // this === VueI18n instance, so the locale property also exists here

  if (choice === 0) {
    return choicesLength < 4 ? 2 : 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || Locales.enUS,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locales.enUS,

  datetimeFormats: {
    [Locales.enUS]: {
      short: {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
    },
    [Locales.ruRU]: {
      short: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
    },
  },

  pluralRules: {
    [Locales.ruRU]: slavicPluralization,
  },

  messages,
});
