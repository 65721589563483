import { Locales } from './locales';

import enUS from './translations/en-US.json';
import ruRU from './translations/ru-RU.json';

export const messages = {
  [Locales.enUS]: enUS,
  [Locales.ruRU]: ruRU,
};

export const defaultLocale = Locales.enUS;
