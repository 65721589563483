<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div
    id="ts-app"
    :class="{'no-min-width': route.name === 'landing'}"
  >
    <div id="app-header">
      <Header
        v-if="route.name !== 'trainer'"
        :separated="route.name !== 'landing'"
      />
    </div>

    <div id="app-content">
      <router-view class="current-view" />
      <!-- <transition name="fade" mode="out-in"> -->
      <!-- <component class="current-view" :is="Component" /> -->
      <!-- </transition> -->
      <!-- </router-view> -->
    </div>

    <div id="app-footer">
      <Footer v-if="route.name === 'profile'" />
    </div>
  </div>
  <!-- </transition> -->
</template>

<script lang="ts">
  import { defineComponent, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useRoute, RouteRecordName } from 'vue-router';

  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';

  export default defineComponent({
    name: 'App',

    components: {
      Header,
      Footer,
    },

    setup() {
      const {
        t, locale, availableLocales,
      } = useI18n({ useScope: 'global' });
      const store = useStore();
      const route = useRoute();

      // Get selected theme (light/dark) or use system
      store.dispatch('theme/init');

      // Get selected language or use language set by browser

      const selectedLanguage = localStorage.getItem('selectedLanguage');

      if (selectedLanguage) {
        locale.value = selectedLanguage;
      } else {
        for (let localeIx = 0; localeIx < availableLocales.length; localeIx += 1) {
          const l = availableLocales[localeIx];

          if (navigator.language.includes(l)) {
            locale.value = l;
          }
        }
      }

      // Update title on locale update

      const updateTitle = (page: RouteRecordName | null | undefined) => {
        document.title = page ? t(`pages.${page.toString()}.meta.title`) : 'Typing School';
      };

      watch(locale, () => {
        updateTitle(route.name);
      });

      // Fix 100% height for Safari
      // 1 - mobile (hiding search bar)
      // 2 - desktop (additional bar when 2 or more sites are opened)

      function setHeight() {
        const vh = window.innerHeight * 0.01;
        document.body.style.setProperty('--vh', `${vh}px`);
      }

      setHeight();

      store.commit('windowResize/addHandler', { handler: setHeight });
      store.dispatch('windowResize/init');
      // window.onresize = setHeight;
      return { route };
    },
  });
</script>

<style lang="scss">
@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

:root {
  box-sizing: border-box;
  font-size: 87.5%;

  @media (min-width: 1200px) {
    font-size: 100%;
  }

  @media (min-width: 1440px) {
    font-size: 112.5%;
  }
}

*,
:before,
:after {
  box-sizing: inherit;
}

* {
  font-family: 'Inter', system-ui, sans-serif;
}

body {
  margin: 0;
  background-color: rgb(var(--color-background));
  color: rgb(var(--color-text-primary));
  line-height: 1.5;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25;
}

a {
  text-decoration: none;
  color: rgb(var(--color-primary));

  &:hover {
    color: rgb(var(--color-primary-hover));
  }

  &:active {
    color: rgb(var(--color-primary-active))
  }
}

// Pages transition

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease-out;
  // transition: opacity .2s ease-out;
}

// App grid

#ts-app {
  $padding: 18px;
  --vh-100: calc(var(--vh, 1vh) * 100);
  --header-height: 3.75rem;
  --header-element-height: 2.5rem;

  min-height: var(--vh-100);
  display: grid;
  grid:
    "header" minmax(var(--header-height, 3.75rem), min-content)
    "messages" min-content
    "content" auto
    "footer" min-content / 100%;

  #app-content {
    $max-width: 1260px;

    grid-area: content;
    padding: 0 $padding;
    width: 100%;

    @media (min-width: #{$max-width + ($padding * 2)}) {
      max-width: $max-width;
      padding: 0;
      margin: 0 auto;
    }

    .current-view {
      min-height: 100%;
    }
  }

  #app-header {
    grid-area: header;
    z-index: 10;
  }

  #messages {
    z-index: 5;
  }

  #app-footer {
    grid-area: footer;
  }

  #app-header nav, #app-footer footer, #messages .message-bar {
    padding-left: $padding;
    padding-right: $padding;
  }

  &:not(.no-min-width) {
    & > * {
      min-width: 900px;
    }
  }
}

// Typography

.title {
  font-size: 3rem;
}

.headline-1 {
  color: rgb(var(--color-text-primary));
  font-weight: 700;
  font-size: 2.5rem;
  padding: 0;
  margin: .5em 0;
  line-height: 1.25;
}

.subtitle {
  color: rgb(var(--color-text-secondary));
  font-weight: 400;
  font-size: 1.25rem;
}

.paragraph {
  color: rgb(var(--color-text-primary));
  font-weight: 400;
  font-size: 1.125rem;
  padding: 0;
  margin: .5em 0;

  &.secondary {
    color: rgb(var(--color-text-secondary));
  }
}
</style>
