import { LayoutProps } from '@/config/keyboards/dataTypes';
import { GetterTree, MutationTree } from 'vuex';

type KeyboardLayoutModuleState = {
  system: string | null,
  format: string | null,
  language: string | null,
  name: string | null,
};

const keyboardLayoutModuleState: KeyboardLayoutModuleState = {
  system: localStorage.getItem('selectedSystem'),
  format: localStorage.getItem('selectedLayoutFormat'),
  language: localStorage.getItem('selectedLayoutLanguage'),
  name: localStorage.getItem('selectedLayoutName'),
};

// TODO fix any in RootState generic type
const keyboardLayoutModuleGetters = <GetterTree<KeyboardLayoutModuleState, null>>{
  props: (state): LayoutProps | null => {
    if (!state.system || !state.format || !state.language || !state.name) return null;

    return {
      system: state.system as LayoutProps['system'],
      format: state.format as LayoutProps['format'],
      language: state.language as LayoutProps['language'],
      name: state.name,
    };
  },
};

const keyboardLayoutModuleMutations = <MutationTree<KeyboardLayoutModuleState>>{
  set(state, props: {settings: LayoutProps}) {
    state.system = props.settings.system;
    state.format = props.settings.format;
    state.language = props.settings.language;
    state.name = props.settings.name;

    localStorage.setItem('selectedSystem', state.system);
    localStorage.setItem('selectedLayoutFormat', state.format);
    localStorage.setItem('selectedLayoutLanguage', state.language);
    localStorage.setItem('selectedLayoutName', state.name);
  },
};

const keyboardLayoutModule = {
  namespaced: true,

  state: keyboardLayoutModuleState,
  getters: keyboardLayoutModuleGetters,
  mutations: keyboardLayoutModuleMutations,
};

export {
  keyboardLayoutModule,
};
