
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Emoji',

    props: {
      // File name from `img/emoji/`
      name: {
        type: String,
        required: true,
      },

      // Inline / separate
      type: {
        type: String,
        default: 'inline',
      },
    },
  });
