<template>
  <button
    class="btn"
    :class="[type, size, state]"
    :disabled="disabled"
    @click="handleClick"
  >
    <img
      v-if="iconPath"
      :src="require(`@/assets/img/${iconPath}`)"
      class="button-icon"
    >

    <div class="content-wrapper">
      <slot />
      <div
        v-if="keybind"
        class="button-keybind"
      >
        [{{ keybind }}]
      </div>
    </div>
  </button>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Button',

    props: {
      type: {
        type: String,
        default: 'primary', // secondary, select
      },
      size: {
        type: String,
        default: 'medium', // small
      },
      state: {
        type: String,
        default: '', // selected, on-selected-block
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      iconPath: {
        type: String,
        required: false, // full path to icon
      },
      keybind: {
        type: String,
        required: false,
      },
      link: {
        type: String,
        required: false,
      },
    },

    setup(props) {
      const handleClick = () => {
        if (!props.link) {
          return true;
        }

        window.location.href = props.link;
        return false;
      };

      return { handleClick };
    },
  });
</script>

<style lang="scss" scoped>
@mixin colorize($bg, $border, $text, $bg-hover, $bg-active) {
  background: rgb(var(--color-#{$bg}));
  border-color: rgb(var(--color-#{$border}));
  color: rgb(var(--color-#{$text}));

  &:not(:disabled):hover {
    background: rgb(var(--color-#{$bg-hover}));
  }

  &:not(:disabled):active {
    background: rgb(var(--color-#{$bg-active}))
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .875em 1.25em;
  border-radius: .375em;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  transition: all .2s ease-out;
  border-width: 1px;
  border-style: solid;

  &.small {
    font-size: .875rem;
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: .5;
  }

  &.primary {
    @include colorize(primary, primary-border, text-on-primary, primary-hover, primary-active);
  }

  &.secondary {
    @include colorize(secondary, secondary-border, secondary-text, secondary-hover, secondary-active);
    background: none;
  }

  &.select {
    @include colorize(secondary, secondary-border, secondary-text, secondary-hover, secondary-active);
    display: flex;
    width: 100%;
    background: none;

    &.selected {
      @include colorize(primary, primary-border, text-on-primary, primary-hover, primary-active);
    }
  }

  // Icon

  .button-icon {
    height: 1em;
    margin-right: .375em;
  }

  .content-wrapper {
    display: flex;
    align-items: baseline;

    // Keybind
    .button-keybind {
      font-size: .8em;
      margin-left: .375em;
      color: rgb(var(--color-text-tertiary-on-primary));
    }
  }

  &.secondary {
    .button-keybind {
      color: rgb(var(--color-text-tertiary));
    }
  }
}
</style>
