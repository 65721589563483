
  import { defineComponent } from 'vue';
  import moment from 'moment';
  import DarkLightImage from './DarkLightImage.vue';

  export default defineComponent({
    name: 'Footer',

    components: {
      DarkLightImage,
    },

    setup() {
      return { moment };
    },
  });
