
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'LanguageSelector',

    setup() {
      const showDialog = ref(false);
      const route = useRoute();

      const { t, locale } = useI18n();

      const langs = ref(new Map([
        ['en-US', { nativeName: 'English', icon: 'en' }],
        ['ru-RU', { nativeName: 'Русский', icon: 'ru' }],
      ]));

      const selectLanguage = (l: string) => {
        localStorage.setItem('selectedLanguage', l);
        locale.value = l;
      };

      return {
        showDialog, langs, locale, t, selectLanguage, route,
      };
    },
  });
