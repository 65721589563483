type RGB = [number, number, number];
type HEX = string;

enum Theme {
  Light = 'light',
  Dark = 'dark'
}

type ThemeColor = {
  theme: Theme,
  color: HEX,
}

type ThemeColorInfo = {
  name: string,
  value: ThemeColor[],
};

class Token {
  private _name: string;

  private _value: ThemeColor[];

  constructor(uniqueName: string, colors: ThemeColor[]) {
    this._name = uniqueName;
    this._value = colors;
  }

  // This method is static, since it doesn't use this -> independent of class instance
  static HEXtoRGB(color: HEX): RGB {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

    if (!result) {
      throw new Error('Wrong HEX!');
    }

    return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
  }

  public getRGB(theme: Theme): RGB {
    for (let themeColorIx = 0; themeColorIx < this._value.length; themeColorIx += 1) {
      const themeColor = this._value[themeColorIx];

      if (themeColor.theme === theme) {
        return Token.HEXtoRGB(themeColor.color);
      }
    }

    throw new Error('Theme not found!');
  }

  public getName(): string {
    return this._name;
  }

  public getInfo(): ThemeColorInfo {
    return {
      name: this._name,
      value: this._value,
    };
  }
}

const colorTokens: Token[] = [];

// Background
colorTokens.push(new Token('background',
  [{ theme: Theme.Light, color: '#ffffff' }, { theme: Theme.Dark, color: '#1f1f1f' }]));
colorTokens.push(new Token('surface',
  [{ theme: Theme.Light, color: '#ffffff' }, { theme: Theme.Dark, color: '#262626' }]));
colorTokens.push(new Token('divider',
  [{ theme: Theme.Light, color: '#E5E6EB' }, { theme: Theme.Dark, color: '#3A3A3A' }]));

// Text
colorTokens.push(new Token('text-primary',
  [{ theme: Theme.Light, color: '#1f1f1f' }, { theme: Theme.Dark, color: '#f7f7f7' }]));
colorTokens.push(new Token('text-secondary',
  [{ theme: Theme.Light, color: '#666666' }, { theme: Theme.Dark, color: '#999999' }]));
colorTokens.push(new Token('text-tertiary',
  [{ theme: Theme.Light, color: '#a6a6a6' }, { theme: Theme.Dark, color: '#595959' }]));
colorTokens.push(new Token('text-tertiary-on-primary',
  [{ theme: Theme.Light, color: '#83C2FF' }, { theme: Theme.Dark, color: '#4AA6FF' }]));

// Primary
colorTokens.push(new Token('primary',
  [{ theme: Theme.Light, color: '#0f8bff' }, { theme: Theme.Dark, color: '#006cd1' }]));
colorTokens.push(new Token('primary-border',
  [{ theme: Theme.Light, color: '#007cf0' }, { theme: Theme.Dark, color: '#007cf0' }]));
colorTokens.push(new Token('primary-hover',
  [{ theme: Theme.Light, color: '#2e9aff' }, { theme: Theme.Dark, color: '#005cb3' }]));
colorTokens.push(new Token('primary-active',
  [{ theme: Theme.Light, color: '#007cf0' }, { theme: Theme.Dark, color: '#007cf0' }]));
colorTokens.push(new Token('text-on-primary',
  [{ theme: Theme.Light, color: '#ffffff' }, { theme: Theme.Dark, color: '#ffffff' }]));
colorTokens.push(new Token('primary-text',
  [{ theme: Theme.Light, color: '#2e9aff' }, { theme: Theme.Dark, color: '#4EA0FF' }]));
colorTokens.push(new Token('primary-light',
  [{ theme: Theme.Light, color: '#c1ddfb' }, { theme: Theme.Dark, color: '#c1ddfb' }]));

// Primary Subdued
colorTokens.push(new Token('primary-subdued',
  [{ theme: Theme.Light, color: '#f0f8ff' }, { theme: Theme.Dark, color: '#09283e' }]));
colorTokens.push(new Token('primary-subdued-border',
  [{ theme: Theme.Light, color: '#a3d3ff' }, { theme: Theme.Dark, color: '#004c94' }]));
colorTokens.push(new Token('primary-subdued-hover',
  [{ theme: Theme.Light, color: '#e0f0ff' }, { theme: Theme.Dark, color: '#002f5c' }]));
colorTokens.push(new Token('primary-subdued-active',
  [{ theme: Theme.Light, color: '#c2e1ff' }, { theme: Theme.Dark, color: '#003f7a' }]));
colorTokens.push(new Token('primary-subdued-text',
  [{ theme: Theme.Light, color: '#696e86' }, { theme: Theme.Dark, color: '#8a8ea3' }]));

// Secondary
colorTokens.push(new Token('secondary',
  [{ theme: Theme.Light, color: '#F2F2F2' }, { theme: Theme.Dark, color: '#262626' }]));
colorTokens.push(new Token('secondary-border',
  [{ theme: Theme.Light, color: '#e0e0e0' }, { theme: Theme.Dark, color: '#3d3d3d' }]));
colorTokens.push(new Token('secondary-border-darker',
  [{ theme: Theme.Light, color: '#c2c2c2' }, { theme: Theme.Dark, color: '#595959' }]));
colorTokens.push(new Token('secondary-hover',
  [{ theme: Theme.Light, color: '#f7f7f7' }, { theme: Theme.Dark, color: '#2e2e2e' }]));
colorTokens.push(new Token('secondary-active',
  [{ theme: Theme.Light, color: '#e0e0e0' }, { theme: Theme.Dark, color: '#3d3d3d' }]));
colorTokens.push(new Token('secondary-text',
  [{ theme: Theme.Light, color: '#3d3d3d' }, { theme: Theme.Dark, color: '#e0e0e0' }]));
colorTokens.push(new Token('secondary-icon',
  [{ theme: Theme.Light, color: '#c2c2c2' }, { theme: Theme.Dark, color: '#595959' }]));

// Keyboard

colorTokens.push(new Token('keyboard-key-border',
  [{ theme: Theme.Light, color: '#97999F' }, { theme: Theme.Dark, color: '#6E6D72' }]));
colorTokens.push(new Token('keyboard-key-shadow',
  [{ theme: Theme.Light, color: '#E0E1E6' }, { theme: Theme.Dark, color: '#090C13' }]));
colorTokens.push(new Token('keyboard-key-gradient-from',
  [{ theme: Theme.Light, color: '#EAEBED' }, { theme: Theme.Dark, color: '#12151A' }]));
colorTokens.push(new Token('keyboard-key-gradient-to',
  [{ theme: Theme.Light, color: '#FFFFFF' }, { theme: Theme.Dark, color: '#25262B' }]));
colorTokens.push(new Token('keyboard-key-text',
  [{ theme: Theme.Light, color: '#9D9FA2' }, { theme: Theme.Dark, color: '#BFBFC7' }]));
colorTokens.push(new Token('keyboard-key-text-on-active',
  [{ theme: Theme.Light, color: '#ffffff' }, { theme: Theme.Dark, color: '#ffffff' }]));

colorTokens.push(new Token('keyboard-key-bg-to-press',
  [{ theme: Theme.Light, color: '#85CAFF' }, { theme: Theme.Dark, color: '#85CAFF' }]));
colorTokens.push(new Token('keyboard-key-to-press-shadow',
  [{ theme: Theme.Light, color: '#3AA1EF' }, { theme: Theme.Dark, color: '#3AA1EF' }]));
colorTokens.push(new Token('keyboard-key-bg-correct',
  [{ theme: Theme.Light, color: '#85CAFF' }, { theme: Theme.Dark, color: '#85CAFF' }]));
colorTokens.push(new Token('keyboard-key-bg-wrong',
  [{ theme: Theme.Light, color: '#FFC7CB' }, { theme: Theme.Dark, color: '#FFC7CB' }]));

colorTokens.push(new Token('keyboard-gradient-from',
  [{ theme: Theme.Light, color: '#EFEFF2' }, { theme: Theme.Dark, color: '#66656B' }]));
colorTokens.push(new Token('keyboard-gradient-to',
  [{ theme: Theme.Light, color: '#DADBE1' }, { theme: Theme.Dark, color: '#3C3B40' }]));
colorTokens.push(new Token('keyboard-bottom-shadow',
  [{ theme: Theme.Light, color: '#C1C4CF' }, { theme: Theme.Dark, color: '#090C13' }]));

// Hands
colorTokens.push(new Token('hand-bg',
  [{ theme: Theme.Light, color: '#D3AA98' }, { theme: Theme.Dark, color: '#D3AA98' }]));
colorTokens.push(new Token('hand-active-finger',
  [{ theme: Theme.Light, color: '#3aa1ef' }, { theme: Theme.Dark, color: '#3aa1ef' }]));
colorTokens.push(new Token('hand-shadow',
  [{ theme: Theme.Light, color: '#C48C75' }, { theme: Theme.Dark, color: '#C48C75' }]));
colorTokens.push(new Token('hand-nail-start',
  [{ theme: Theme.Light, color: '#E5C9BA' }, { theme: Theme.Dark, color: '#E5C9BA' }]));
colorTokens.push(new Token('hand-nail',
  [{ theme: Theme.Light, color: '#E0B8B8' }, { theme: Theme.Dark, color: '#E0B8B8' }]));

// Danger
colorTokens.push(new Token('danger-text',
  [{ theme: Theme.Light, color: '#FF576C' }, { theme: Theme.Dark, color: '#FF576C' }]));
colorTokens.push(new Token('danger-bg',
  [{ theme: Theme.Light, color: '#FFC7CE' }, { theme: Theme.Dark, color: '#653238' }]));

// Success
colorTokens.push(new Token('success-text',
  [{ theme: Theme.Light, color: '#96C691' }, { theme: Theme.Dark, color: '#96C691' }]));
colorTokens.push(new Token('success-bg',
  [{ theme: Theme.Light, color: '#ECF7E7' }, { theme: Theme.Dark, color: '#455040' }]));

// Warning
colorTokens.push(new Token('warning-text',
  [{ theme: Theme.Light, color: '#E5CD07' }, { theme: Theme.Dark, color: '#F9E542' }]));
colorTokens.push(new Token('warning-bg',
  [{ theme: Theme.Light, color: '#F9E542' }, { theme: Theme.Dark, color: '#F9E542' }]));

export { Theme, Token, colorTokens };
