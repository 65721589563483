import firebase from 'firebase/app';
import 'firebase/analytics';
// import 'firebase/auth'
// import 'firebase/firestore'
// import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDkm8gVc9UO1q-KulM6ZsoYbDJN4EbwhvI',
  authDomain: 'typing-school-old.firebaseapp.com',
  projectId: 'typing-school-old',
  storageBucket: 'typing-school-old.appspot.com',
  messagingSenderId: '452752870492',
  appId: '1:452752870492:web:878e76ddc2ef3f42f62a99',
  measurementId: 'G-2F2KQGWMDE',
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

// utils
// const db = firebase.firestore()
// const auth = firebase.auth()
// const storage = firebase.storage()

// collection references
// const usersCollection = db.collection('users')

// export utils/refs
export { analytics };
