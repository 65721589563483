import { createStore } from 'vuex';

import { themeModule } from './theme';
import { keyboardLayoutModule } from './keyboardLayout';
import { lessonModule } from './lesson';
import { windowResize } from './windowResize';

export default createStore({
  modules: {
    theme: themeModule,
    layout: keyboardLayoutModule,
    lesson: lessonModule,
    windowResize,
  },
});
