import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import i18n from '@/locales/i18n';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('selectedLayoutName')) return next('/profile');
      return next();
    },
  },
  {
    path: '/keyboard_setup',
    name: 'layout-setup',
    component: () => import(/* webpackChunkName: "layout-setup" */ '@/views/LayoutSetup.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('selectedLayoutName')) return next('/keyboard_setup');
      return next();
    },
  },
  {
    path: '/trainer',
    name: 'trainer',
    component: () => import(/* webpackChunkName: "trainer" */ '@/views/trainer/Trainer.vue'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '@/views/Result.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const titlePath = `pages.${String(to.name)}.meta.title`;
  document.title = i18n.global.te(titlePath) ? i18n.global.t(titlePath) : 'Typing School';
  next();
});

export default router;
