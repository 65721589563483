import { ActionTree, MutationTree } from 'vuex';
import { Theme, colorTokens } from '@/config/colors';

type ThemeModuleState = {
  activeTheme: Theme,
};

const themeModuleState: ThemeModuleState = {
  activeTheme: Theme.Light,
};

const themeModuleMutations = <MutationTree<ThemeModuleState>>{
  change(state, props: {newTheme: Theme}) {
    state.activeTheme = props.newTheme;
  },
};

// TODO fix any in RootState generic type
const themeModuleActions = <ActionTree<ThemeModuleState, null>> {
  init(context) {
    let theme: Theme | null = null;

    if (localStorage.getItem('selectedTheme')) {
      // User has selected theme
      theme = <Theme>localStorage.getItem('selectedTheme');
    } else if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // User has system color preferences
      theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.Dark : Theme.Light;
    }

    if (theme === Theme.Dark) {
      context.dispatch('setDark');
    } else {
      context.dispatch('setLight');
    }
  },

  toggle(context) {
    const newTheme = context.state.activeTheme === Theme.Light ? Theme.Dark : Theme.Light;

    context.commit('change', { newTheme });
    localStorage.setItem('selectedTheme', newTheme);
    context.dispatch('updateProperties');
  },

  setLight(context) {
    context.commit('change', { newTheme: Theme.Light });
    context.dispatch('updateProperties');
  },

  setDark(context) {
    context.commit('change', { newTheme: Theme.Dark });
    context.dispatch('updateProperties');
  },

  updateProperties(context) {
    const theme = context.state.activeTheme;

    for (let tokenIx = 0; tokenIx < colorTokens.length; tokenIx += 1) {
      const token = colorTokens[tokenIx];

      const root = document.documentElement.style;

      const tokenName = token.getName();
      const RGB = token.getRGB(theme);

      root.setProperty(`--color-${tokenName}`, `${RGB[0]} ${RGB[1]} ${RGB[2]}`);
    }
  },
};

const themeModule = {
  namespaced: true,

  state: themeModuleState,
  mutations: themeModuleMutations,
  actions: themeModuleActions,
};

export {
  themeModule,
};
